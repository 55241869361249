import wordmark from '../icons/wordmark.svg';

import { useState } from 'react';
import './App.css';
import Network from './Network';
import Timezone from './Timezone';
import { defaultConfig as defaultNetworkConfig } from './Network';
import { defaultConfig as defaultProxyConfig } from './Proxy';
import { defaultConfig as defaultNtpConfig } from './Ntp';
import { defaultDeviceTimezone } from './Timezone';
import { download } from '../libs/utils';
import { Config } from './types';
import Output, { generateConfig } from './Output';
import Advanced from './Advanced';
import OnBoarding from './OnBoarding';
import { useForm, SubmitHandler } from 'react-hook-form';
import 'ace-builds/webpack-resolver';

const getTimestamp = () => {
  return Date.now();
};

const defaultConfig = {
  version: '1.0.2',
  network: defaultNetworkConfig,
  proxy: defaultProxyConfig,
  ntp: defaultNtpConfig,
  timezone: defaultDeviceTimezone,
  timestamp: getTimestamp(),
  certificates: [],
  enrollment_token: '',
};

interface IFormInput {
  ipv4Address: string;
}

function App() {
  const [config, setConfig] = useState<Config>(defaultConfig);
  const [isOnBoardingCompleted, setOnBoardingCompleted] = useState(false);

  const { register, handleSubmit, errors, setValue, unregister, setError, clearErrors } = useForm();
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    download('scosprov.json', JSON.stringify(generateConfig(config), undefined, 4));
  };

  const handleConfigChange = (key: keyof Config, value: any) => {
    setConfig({
      ...config,
      [key]: value,
      timestamp: getTimestamp(),
    });
  };

  if (window.location.pathname !== '/config') {
    if (!isOnBoardingCompleted) {
      return <OnBoarding oncomplete={() => setOnBoardingCompleted(true)} />;
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="config-content">
      <Navbar />
        <main>
          <div className="wrapper">
            <section className="basic-title">
              <h2>Configuration Generator</h2>
            </section>
            <section className="main-container width50">
              <div className="board">
                <Network
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  unregister={unregister}
                  handleConfigChange={handleConfigChange}
                  config={config.network}
                  setError={setError}
                  clearError={clearErrors}
                />
                {config.network.mode && <Timezone handleConfigChange={handleConfigChange} config={config.timezone} />}
              </div>
              <ol className="note">
                <li>Hidden Network supported if your device running SCOS V1.4.31 or later.</li>
                <li>Custom TCP/IP & DNS Configuration supported for devices running SCOS V1.4.33 or later.</li>
              </ol>
            </section>
            <section className="advanced-container width50">
              {config.network.mode && (
                <Advanced
                  proxy={config.proxy}
                  certificates={config.certificates}
                  ntp={config.ntp}
                  handleConfigChange={handleConfigChange}
                  config={config.network}
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  unregister={unregister}
                />
              )}
              <hr />
              <Output config={config} />
            </section>
          </div>
        </main>
      </div>
    </form>
  );
}

function Navbar () {

  if (window.location.pathname === '/config') return <></>;

  return ( 
    <>
      <nav className="header" role="navigation">
          <div className="wrapper">
            <img className="logo" src={wordmark} alt="wordmark" />
          </div>
      </nav>
    </> 
  )
}

export default App;
