import { useCallback } from 'react';
import { Config } from './types';
import './Ntp.css';
import addmore from '../icons/plus-circle.svg';
import remove from '../icons/close.svg';
export const defaultConfig: string[] = [''];

interface NtpProps {
  handleConfigChange: (key: keyof Config, value: any) => void;
  config: string[];
}

function NTP({ handleConfigChange, config: ntp }: NtpProps) {
  const handleNtpChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      ntp[index] = e.target.value;
      handleConfigChange('ntp', [...ntp]);
    },
    [ntp, handleConfigChange]
  );

  const onRemoveNtp = useCallback(
    (index: number) => {
      const newNtp = ntp.filter((_, i) => {
        return index !== i;
      });

      handleConfigChange('ntp', newNtp);
    },
    [handleConfigChange, ntp]
  );

  const onAddNtp = useCallback(() => {
    handleConfigChange('ntp', [...ntp, '']);
  }, [ntp, handleConfigChange]);

  return (
    <div className="form-group">
      <label id="ntpTitle">NTP</label>
      <div className="input-container multiple">
        {ntp.map((value, index) => (
          <div className="control" key={index}>
            <input
              id="ntpInput"
              className="input "
              type="text"
              value={value}
              placeholder="0.pool.ntp.org"
              onChange={(e) => handleNtpChange(e, index)}
            />
            <div className="remove-ntp-btn" onClick={() => onRemoveNtp(index)}>
              <img src={remove} alt="remove" className="remove" />
            </div>
          </div>
        ))}
      </div>
      <button id="onAddNtpButton" className="button add-more-btn" onClick={onAddNtp} type="button">
        <img src={addmore} alt="addmore" className="addmore" />
        Add more
      </button>
    </div>
  );
}

export default NTP;
